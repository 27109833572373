import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
// import ShopCategory from "../components/ecwid/category"

class ContactUs extends React.Component {
	componentDidMount() {
		if (window) {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = `https://forms.webriq.com/js/initForms`
			const headScript = document.getElementsByTagName('script')[0]
			headScript.parentNode.insertBefore(script, headScript)
		}
	}
	render() {
		let params = this.props.location.pathname
		console.log(params)
		return (
			<Layout
				pathname={params}
				head={{
					name: 'Teak Veneer Plywood Shipping Quote -- Full Uncut Sheets',
					meta: {
						description:
							'Teak Veneer Plywood Shipping Quote -- Full Uncut Sheets Please use this form to request price &#38; shipping quotes for full uncut sheets of teak veneer plywood from Buck Woodcraft.',
					},
				}}
			>
				<div className="container py-5">
					<div className="row">
						{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
						<div className="col-md-8 shop-items mx-auto">
							<div className="page-content">
								<h4 className="section-heading page-mt">
									Teak Veneer Plywood Shipping Quote -- Full Uncut Sheets
								</h4>
								<p>
									Please use this form to request price &#38; shipping quotes
									for full uncut sheets of teak veneer plywood from Buck
									Woodcraft. If you also need half-sheet quantities, we can add
									those to your full-sheet shipment. Simply describe your
									requiremens where indicated below.
								</p>
								<p>
									Shipments to a Florida address will be subject to sales tax.
									Truck freight shipments to a residence are higher than if
									shipped to a business with a loading dock and/or lift truck.
								</p>
								<form
									action="/thank-you"
									method="post"
									data-form-id="5c52aef38ba9a30a93a2a013"
									webriq="true"
								>
									<div className="form-group">
										<label>Your Name:</label>
										<input
											className="form-control"
											type="text"
											name="Name"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Your Email Address</label>
										<input
											className="form-control"
											type="text"
											name="Email"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Contact Phone Number</label>
										<input
											className="form-control"
											type="number"
											name="Phone Number"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Ship-To Location</label>
										<br />
										<input
											className="mr-1"
											type="radio"
											name="Ship-To Location"
											value="Residence, Retail Shop, Dr's Office, etc."
											required="required"
										/>
										Residence, Retail Shop, Dr's Office, etc.
										<br />
										<input
											className="mr-1"
											type="radio"
											name="Ship-To Location"
											value="Business With Loading Dock Or Lift Truck "
											required="required"
										/>
										Business With Loading Dock Or Lift Truck
										<br />
									</div>
									<div className="form-group">
										<label>Ship-To Zip Code</label>
										<input
											className="form-control"
											type="number"
											name="Zip Code"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>
											Standard King Starboard -- Please Specify Quantity & Color
											For Each Half Sheet Size/Thickness
										</label>
										<textarea
											className="form-control"
											rows="4"
											name="Standard King Starboard -- Please Specify Quantity & Color For Each Half Sheet Size/Thickness"
										/>
									</div>
									<div className="form-group">
										<label>
											King Starboard Anti Skid AS -- White/White Only -- Please
											Specify Quantity Required For Each Thickness You Want To
											Buy.
										</label>
										<textarea
											className="form-control"
											rows="4"
											name="King Starboard Anti Skid AS -- White/White Only -- Please Specify Quantity Required For Each Thickness You Want To Buy."
										/>
									</div>
									<div className="form-group">
										<label>Additional Comments Or Questions</label>
										<textarea
											className="form-control"
											rows="4"
											name="Additional Comments Or Questions"
										/>
									</div>
									<div className="webriq-recaptcha mb-3" />
									<div className="form-group">
										<button
											className="btn btn-primary btn-block blue-btn"
											type="submit"
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ContactUs

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
